<template>
	<div class="login-layout">
		<div class="main-container">
			<div class="main-content">
				<div class="row">
					<div class="col-sm-10 col-sm-offset-1">
						<div class="login-container">
							<div class="center">
								<h1 class="mt-20">
									<i class="ace-icon fa fa-wifi white"></i>
									<span class="red">Admi</span><span class="white" id="id-text2">N</span>
								</h1>
							</div>

							<div class="space-6"></div>

							<div class="position-relative">
								<div id="login-box" class="login-box visible widget-box no-border">
									<div class="widget-body">
										<div class="widget-main">
											<h4 class="header blue lighter bigger">
												<i class="ace-icon fa fa-coffee green"></i>
												Entre com suas Informações
											</h4>

											<div class="space-6"></div>

											<router-view />
										</div><!-- /.widget-main -->
									</div><!-- /.widget-body -->
								</div><!-- /.login-box -->
							</div><!-- /.position-relative -->
						</div>
					</div><!-- /.col -->
				</div><!-- /.row -->
			</div><!-- /.main-content -->
		</div><!-- /.main-container -->
	</div>
</template>

<script>
import jwt from 'jwt-simple'
export default {
	data() {
		return {
			user: false
		}
	},
	created() {
		let user = localStorage.getItem('user')
		if (user) {
			this.user = JSON.parse(jwt.decode(user, 'vox'))
			this.$router.push('/')
		}
	}
}
</script>

<style lang="stylus">
h1 {
    font-size: revert;
    font-weight: revert;
}
body
    background-color #1D2024
</style>
